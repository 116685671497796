$background1: #211E1C;
$background2: #423C38;

$foreground1: #f8f7f6;
$foreground2: #e8e3d9;
$foreground3: #e4dbaf;

$action-rgb: 238, 123, 48;
$action: #EE7B30;
$accept: #00e676;
$reject: #ff1744;