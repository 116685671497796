@import "../../theme.scss";

.container {
  min-height: 85vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.formContainer {
  width: 300px;
}

.error {
  font-size: 0.8rem;
  color: $reject;
}

.loginForm {
  display: flex;
  flex-direction: column;
}

.loginFormItem {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
